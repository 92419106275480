import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125')
];

export const server_loads = [0,2,4,5,6,8,9,11,12,13,15,16];

export const dictionary = {
		"/": [~24],
		"/admin": [~25,[2]],
		"/admin/audit-log": [~29,[2]],
		"/admin/labs": [~30,[2]],
		"/admin/materials": [~31,[2]],
		"/admin/permissions": [~32,[2]],
		"/admin/[distChannel]/materials": [~26,[2]],
		"/admin/[distChannel]/materials/[code]": [~27,[2]],
		"/admin/[distChannel]/prices": [~28,[2]],
		"/api/orders/new/scleral": [33],
		"/api/v1/docs": [~34],
		"/billing": [~35,[3]],
		"/billing/[distChannel]": [~36,[3]],
		"/billing/[distChannel]/orders": [~37,[3]],
		"/billing/[distChannel]/orders/[orderId]": [~38,[3]],
		"/clinic": [~39,[4]],
		"/clinic/admin": [~40,[4]],
		"/clinic/calculators": [~41,[4]],
		"/clinic/orders": [~42,[4]],
		"/clinic/orders/[orderId]": [~43,[4]],
		"/clinic/orders/[orderId]/new": [~45,[4]],
		"/clinic/orders/[orderId]/new/scleral/step1": [~46,[4,5]],
		"/clinic/orders/[orderId]/new/scleral/step2": [~47,[4,5]],
		"/clinic/orders/[orderId]/new/scleral/step3": [~48,[4,5]],
		"/clinic/orders/[orderId]/new/scleral/step4": [~49,[4,5]],
		"/clinic/orders/[orderId]/new/scleral/step5": [~50,[4,5]],
		"/clinic/orders/[orderId]/[eye]/lens/update": [~44,[4]],
		"/clinic/patients": [~51,[4]],
		"/clinic/patients/new": [~60,[4]],
		"/clinic/patients/[patientId]": [~52,[4]],
		"/clinic/patients/[patientId]/calculators/forge": [~53,[4]],
		"/clinic/patients/[patientId]/calculators/scleral/step1": [~54,[4,6]],
		"/clinic/patients/[patientId]/calculators/scleral/step2": [~55,[4,6]],
		"/clinic/patients/[patientId]/calculators/scleral/step3": [~56,[4,6]],
		"/clinic/patients/[patientId]/calculators/scleral/step4": [~57,[4,6]],
		"/clinic/patients/[patientId]/calculators/scleral/step5": [~58,[4,6]],
		"/clinic/patients/[patientId]/calculators/scleral/step6": [~59,[4,6]],
		"/distributor": [~61,[7]],
		"/distributor/[distChannel]": [~62,[7,8]],
		"/distributor/[distChannel]/calculators": [~63,[7,8]],
		"/distributor/[distChannel]/diagnostic": [~64,[7,8]],
		"/distributor/[distChannel]/labels": [~65,[7,8]],
		"/distributor/[distChannel]/orders": [~66,[7,8]],
		"/distributor/[distChannel]/orders/create": [~76,[7,8]],
		"/distributor/[distChannel]/orders/create/practices": [~77,[7,8]],
		"/distributor/[distChannel]/orders/create/practices/[practiceId]": [~78,[7,8]],
		"/distributor/[distChannel]/orders/create/practices/[practiceId]/patients": [~79,[7,8]],
		"/distributor/[distChannel]/orders/create/practices/[practiceId]/patients/[patientId]": [~80,[7,8]],
		"/distributor/[distChannel]/orders/[orderId]": [~67,[7,8]],
		"/distributor/[distChannel]/orders/[orderId]/new": [~70,[7,8]],
		"/distributor/[distChannel]/orders/[orderId]/new/scleral/step1": [~71,[7,8,9]],
		"/distributor/[distChannel]/orders/[orderId]/new/scleral/step2": [~72,[7,8,9]],
		"/distributor/[distChannel]/orders/[orderId]/new/scleral/step3": [~73,[7,8,9]],
		"/distributor/[distChannel]/orders/[orderId]/new/scleral/step4": [~74,[7,8,9]],
		"/distributor/[distChannel]/orders/[orderId]/new/scleral/step5": [~75,[7,8,9]],
		"/distributor/[distChannel]/orders/[orderId]/[eye]/lens/spec/[rowNumber]": [~68,[7,8]],
		"/distributor/[distChannel]/orders/[orderId]/[eye]/lens/update": [~69,[7,8]],
		"/distributor/[distChannel]/patients": [~81,[7,8]],
		"/distributor/[distChannel]/patients/new/practices": [~92,[7,8]],
		"/distributor/[distChannel]/patients/new/practices/[practiceId]": [~93,[7,8]],
		"/distributor/[distChannel]/patients/[patientId]": [~82,[7,8]],
		"/distributor/[distChannel]/patients/[patientId]/calculators/forge": [~83,[7,8,10]],
		"/distributor/[distChannel]/patients/[patientId]/calculators/scleral/step1": [~84,[7,8,11]],
		"/distributor/[distChannel]/patients/[patientId]/calculators/scleral/step2": [~85,[7,8,11]],
		"/distributor/[distChannel]/patients/[patientId]/calculators/scleral/step3": [~86,[7,8,11]],
		"/distributor/[distChannel]/patients/[patientId]/calculators/scleral/step4": [~87,[7,8,11]],
		"/distributor/[distChannel]/patients/[patientId]/calculators/scleral/step5": [~88,[7,8,11]],
		"/distributor/[distChannel]/patients/[patientId]/calculators/scleral/step6": [~89,[7,8,11]],
		"/distributor/[distChannel]/patients/[patientId]/merge": [~90,[7,8]],
		"/distributor/[distChannel]/patients/[patientId]/merge/[incomingPatientId]": [~91,[7,8]],
		"/distributor/[distChannel]/practices": [~94,[7,8]],
		"/distributor/[distChannel]/practices/[practiceId]": [~95,[7,8]],
		"/kb": [~96,[12]],
		"/kb/search": [~98,[12]],
		"/kb/[slug]": [~97,[12,13],[,14]],
		"/lab": [~99],
		"/lab/[labCode]": [100,[15]],
		"/lab/[labCode]/admin": [~108,[15]],
		"/lab/[labCode]/checklist": [~109,[15]],
		"/lab/[labCode]/clear_submit_bar_codes": [~110,[15]],
		"/lab/[labCode]/clinics": [~111,[15]],
		"/lab/[labCode]/errored-orders": [~112,[15]],
		"/lab/[labCode]/lens/material": [~113,[15]],
		"/lab/[labCode]/print/forms": [~114,[15]],
		"/lab/[labCode]/qc": [~115,[15]],
		"/lab/[labCode]/[orderId]/diagnosticSet/qc": [~105,[15]],
		"/lab/[labCode]/[orderId]/print/forms": [~106,[15]],
		"/lab/[labCode]/[orderId]/qc": [~107,[15]],
		"/lab/[labCode]/[orderId]/[eye]/lens/update": [~101,[15]],
		"/lab/[labCode]/[orderId]/[eye]/qc": [~102,[15]],
		"/lab/[labCode]/[orderId]/[eye]/schematic": [~103,[15,16]],
		"/lab/[labCode]/[orderId]/[eye]/schematic/[revision]": [~104,[15,16]],
		"/login": [116,[17]],
		"/orders/[orderId]/lens/[eye]/profile": [~117],
		"/tools": [~118,[18]],
		"/tools/(without-sidebar)/back-surface-simulator": [121,[22]],
		"/tools/(with-sidebar)/rigid-lens-bvp": [~119,[18,19,20]],
		"/tools/(with-sidebar)/soft-toric-bvp": [~120,[18,19,21]],
		"/troubleshoot": [~122,[23]],
		"/troubleshoot/orders": [~123,[23]],
		"/v1/kb": [~124],
		"/v1/kb/[slug]": [~125]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';